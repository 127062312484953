.dashboard-wrapper {
	font-family: "Euclid Circular A";

	.card {
		margin-bottom: 0;
	}

	.welcome-wrapper {
		padding-top: 20px;
		padding-bottom: 40px;

		h2 {
			color: #041928;
			font-size: 24;
			font-weight: bold;
			user-select: none;
			b {
				text-decoration: underline;
				color: #ef5050;
				cursor: pointer;
			}
		}
	}

	.bottom-image {
		height: 30px;
		img {
			height: 100%;
			width: 100%;
		}
	}

	.margin-top {
		margin-top: 30px;
	}

	.split-text {
		margin: 0 !important;
	}
	.ingredient-section__content {
		.concept-products-list {
			padding: 0;
		}

		.ingredient-item {
			display: inline-block;
			position: relative;
			margin: 10px 20px 10px 0;
			width: 370px;
			padding: 12px 8px;
		}
	}

	.products-section__content {
		.concept-products-list {
			padding: 0;
		}
		.product-item {
			width: 370px;
			display: inline-block;
			position: relative;
			margin: 10px 15px;
		}

		.ingredient-item {
			display: inline-block;
			position: relative;
			margin: 10px 20px 10px 0;
			width: 370px;
			padding: 12px 8px;
		}
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
	}

	.wrapper-grid {
		display: grid;
		flex-wrap: wrap;
		display: grid;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		grid-column-gap: 16px;
		grid-row-gap: 16px;
	}

	.wrapper__options-big {
		display: flex;
		flex: 2;
		gap: 30px;

		.reset_padding_left {
			padding-left: 0;
		}
	}

	.wrapper__options {
		background: rgba(249, 249, 250, 0.5);
		box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.25);
		border-radius: 12px;
		position: relative;
		flex: 1;
		cursor: default;
		overflow: hidden;
		cursor: pointer;
	}

	.portfolio__options {
		background: rgba(249, 249, 250, 0.5);
		box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.25);
		border-radius: 12px;
		position: relative;
		flex: 1;
		cursor: default;
		overflow: hidden;
	}

	.wrapper__container {
		padding-left: 60px;
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: space-between;
	}

	.empty__container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		.options__heading {
			padding: 0;
		}
	}

	.options__heading {
		font-size: 20px;
		font-weight: 700;
		line-height: 27px;
		color: #041928;
		padding-top: 40px;
	}

	.options__content {
		font-size: 14px;
		line-height: 17px;
		color: #041928;
		padding-top: 20px;
		min-height: 65px;
	}

	.portfolio__content {
		font-size: 14px;
		line-height: 17px;
		color: #041928;
		padding-top: 20px;
		min-height: 65px;
		display: flex;
		justify-content: space-between;
		padding-right: 40px;
	}

	.options__footer {
		padding-top: 35px;
		padding-bottom: 35px;
	}
	.options__link {
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		color: #395efa;
		cursor: pointer;
	}
	.option__img {
		height: 100px;
		width: 100px;
		position: absolute;
		right: 100px;
		bottom: 0px;
		img {
			width: 100%;
		}
	}

	.card_image {
		position: absolute;
		height: 130px;
		overflow: hidden;
		margin-right: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 15px;
		right: 0px;
		.dynamic_image {
			width: 100%;
		}
		img {
			width: 130px;
		}
	}

	.nav-tabs {
		padding-top: 10px;
		display: flex;
		justify-content: flex-end;
		border-bottom: 0;
	}
	.tab-content {
		flex: 1;
	}
	.nav-link {
		padding: 0 !important;
		cursor: pointer;
	}
	.nav-link:hover {
		border-color: rgba(249, 249, 250, 0.5) !important;
	}
	.options--body__footer {
		padding-top: 50px;
		padding-bottom: 35px;
	}
	.nav-item {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: grey;
		padding-right: 25px;
	}
	.nav-active-item {
		color: #53ce7c !important;
		border-bottom: 2px solid #53ce7c !important;
	}

	.options-body {
		display: flex;
		flex-direction: column;
		position: relative;
		user-select: none;
	}
	.options_content {
		display: flex;
		align-items: center;
	}
	.options-body__heading {
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;
		color: #041928;
		padding: 8px 0 0px;
	}
	.options-body__paragraph {
		font-size: 14px;
		line-height: 17px;
		color: #041928;
		padding: 0 0 35px;
		display: flex;
	}

	.options-body__content {
		flex: 1;
		padding-left: 40px;
	}
	.left-arrow {
		padding: 20px 10px 20px 20px;
		cursor: pointer;
	}
	.right-arrow {
		padding: 20px 20px 20px 10px;
		cursor: pointer;
	}
	.options__navigation {
		padding-top: 15px;
	}

	// /*//////////////////////////////*/

	.recommendation-section {
		background-color: "white";

		.wrapper__card {
			cursor: pointer;
		}

		.card__content {
			background: #f9f9fa;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex: 1;
			align-items: top;
			padding: 5px 15px;
			align-items: flex-start;
		}
	}
	.recommendation-section__comment {
		display: flex;
		padding-top: 57px;
		text-align: center;
	}
	.comment__content {
		padding-right: 30px;
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;
		color: #041928;
	}
	.comment__link {
		cursor: pointer;
		font-weight: 700;
		font-size: 18px;
		color: #395efa;
		user-select: none;
	}

	.content {
		padding-top: 30px;
		padding-bottom: 27px;
	}
	.content-wrapper {
		padding-bottom: 26px;
	}

	.wrapper__card {
		// font-family: Inter;
		flex: 1 1;
		// min-width: 390px;
		max-width: 390px;
		box-shadow: 0px 0px 20px rgba(4, 25, 40, 0.25);
		border-radius: 12px;
		overflow: hidden;
		border-bottom: 4px solid #17c9dc;
	}
	.content__items {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 24px 0px 17px;
	}

	.content__heading {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #041928 !important;
		flex-direction: column;
		justify-content: center;
		display: flex;

		.product__name {
			white-space: nowrap;
			text-overflow: ellipsis;
			flex: 1;
			max-width: 100%;
			overflow: hidden;
			display: block;
		}

		.brand__name {
			color: grey;
		}

		.status__bar {
			font-weight: 400;
			color: grey;
		}
		.supplier__name {
			color: gray !important;
		}
	}
	.star__svg {
		padding-right: 19px;
	}

	.card__svgs {
		display: flex;
	}
	.card__img {
		height: 170px;
		background-size: cover;
		display: flex;
		background-size: cover;
		img {
			width: 100%;
			margin: 0 !important;
		}
	}
	.card__comment {
		padding: 0 10px 9px 18px;
		font-weight: 400;
		font-size: 16px;
		line-height: 18px;
		color: gray;
		font-style: italic;
		display: flex;
		justify-content: space-between;
	}

	.products-section {
		// background: #f3f5fd;

		.wrapper__card {
			border: 0;
			cursor: pointer;
		}

		.card__content {
			background: #f9f9fa;
			// display: flex;
			flex-direction: row;
			justify-content: space-between;
			// flex: 1 1;
			align-items: center;
			padding: 5px 15px;
		}
	}
	.products-section__comment {
		display: flex;
		padding-top: 40px;
	}

	.products-section {
		.content__items {
			padding-top: 7px;
		}
	}

	.buttons {
		margin-top: 40px;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
	}
	.button-primary {
		margin-right: 30px;
		background: #395efa;
		color: #f9f9fa;
	}
	.button-style {
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
	}
	/*///////////////////*/

	.products__actions {
		.header-svgs {
			display: flex;
			justify-content: space-between;
		}
		.svg--back {
			padding: 25px 0 0 24px;
			cursor: pointer;
		}
		.svg--cancel {
			padding: 31px 38px 0 0;
			cursor: pointer;
		}

		.section {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 115px;
		}
		.section__content {
			text-align: center;
		}

		.content__heading {
			font-weight: 700;
			font-size: 36px;
			line-height: 49px;
			color: #000000;
			padding-bottom: 38px;
		}

		.section__option {
			display: flex;
			padding-top: 61px;
			gap: 47px;
		}
		.content__paragraph {
			font-weight: 500;
			font-size: 24px;
			line-height: 29px;
			color: #000000;
		}
		.option__svg {
			background: #ffffff;
			box-sizing: border-box;
			border-radius: 12px;
			width: 150px;
			height: 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			cursor: pointer;
		}

		.svg-dashed {
			border: 1px dashed rgba(4, 25, 40, 0.25);
		}
		.svg__content {
			display: flex;
		}
		.action__items {
			text-align: center;
			justify-content: center;
			padding-top: 23px;
		}
		.items__heading {
			font-weight: bold;
			font-size: 14px;
			line-height: 18px;
			color: #000000;
		}
		.items__name {
			font-size: 14px;
			line-height: 18px;
			color: #000000;
		}
	}
}
.products__actions {
	.header-svgs {
		display: flex;
		justify-content: space-between;
	}
	.svg--back {
		padding: 25px 0 0 24px;
		cursor: pointer;
	}
	.svg--cancel {
		padding: 31px 38px 0 0;
		cursor: pointer;
	}

	.section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 115px;
	}
	.section__content {
		text-align: center;
	}

	.content__heading {
		font-weight: 700;
		font-size: 36px;
		line-height: 49px;
		color: #000000;
		padding-bottom: 38px;
		flex: 1;
	}

	.section__option {
		display: flex;
		padding-top: 61px;
		gap: 47px;
	}
	.content__paragraph {
		font-weight: 500;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
	}
	.option__svg {
		background: #ffffff;
		box-sizing: border-box;
		border-radius: 12px;
		width: 150px;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		cursor: pointer;
	}

	.svg-dashed {
		border: 1px dashed rgba(4, 25, 40, 0.25);
	}
	.svg__content {
		display: flex;
	}
	.action__items {
		text-align: center;
		justify-content: center;
		padding-top: 23px;
	}
	.items__heading {
		font-weight: bold;
		font-size: 14px;
		line-height: 18px;
		color: #000000;
	}
	.items__name {
		font-size: 14px;
		line-height: 18px;
		color: #000000;
	}
}
