.account-dropdown {
	.dropdown-menu-right {
		border-radius: 6px;
		border-top: 2px solid blue;
		.dropdown-item:focus {
			outline: none !important;
		}
		.dropdown-item:first-of-type {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}
}

.header-notification-container {
	width: 100%;

	button.dropdown-item {
		display: flex;
		padding: 0px;
	}

	button.dropdown-item:focus {
		outline: none !important;
	}
	.dropdown-menu {
		max-height: 400px;
		overflow: auto;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		border-top: 2px solid blue;
		width: 400px;
		left: -19rem;
		&.brand {
			left: -5.5rem;
			width: 294px;
		}
		box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
		padding-bottom: 0px;

		border-radius: 6px;

		.see-all {
			padding: 15px 0px !important;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.5px;
			color: #3f65f1;
			display: flex;
			justify-content: center;
			padding: 5px 0;
			width: 100%;
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	.dropdown__notification {
		padding: 19px 15px 19px 15px;
		flex-direction: row;
		display: flex;
		border-radius: 8px;
		cursor: pointer;
		max-width: 100%;
		align-items: center;
		justify-content: center;
		z-index: 1500;
		overflow: hidden;
		&.brand {
			padding: 19px 15px 19px 2px !important;
		}

		.notification__icon {
			align-self: center;
		}

		.notification__detail {
			margin-left: 15px;
			flex-direction: column;
			display: flex;
			flex: 1;
			justify-content: center;
			overflow: hidden;
			&:hover {
				overflow-x: scroll;
			}

			.notification__title {
				font-size: 14px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: 0.5px;
				color: #273f52;
				.brand-icon {
					margin-right: 10px;
				}
			}

			.notification__description {
				font-size: 14px;
				font-weight: normal;
				color: #657280;
			}
		}
	}

	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
		color: grey;
	}
}
