.live-search-width {
	@media only screen and (min-width: 1200px) {
		width: 700px;
		max-with: 1200px;
	}
	@media only screen and (max-width: 800px) {
		width: 300px;
	}
}

.search-list {
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: row wrap;
	li {
		width: 100%;
		min-height: 40px;
		text-overflow: ellipsis;
		margin: 5px 5px;
		white-space: pre-wrap;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		border: solid 1px var(--powder-blue);
		position: relative;
		border-radius: 6px;
		align-items: center;
		&.no-action {
			justify-content: flex-start;
			a {
				margin-left: 20%;
			}
		}
		img {
			width: 24px;
			height: 24px;
			border-radius: 6px;
		}

		.rounded-circle {
			position: relative;
			display: inline-block;
			right: 10px;
			visibility: visible;
			width: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			span {
				display: none;
			}
		}

		.rounded-circle:hover:not(.loading) {
			transition: width 0.2s ease-out;
			visibility: visible;
			cursor: pointer;
			border-radius: 25px !important;
			overflow: hidden;
			padding: 3px 6px;
			&.add {
				width: 125px;
			}
			&.remove {
				width: 160px;
			}

			svg {
				display: none;
			}
			span {
				display: inline-block;
				white-space: nowrap;
				&.add-text {
					color: #3f65f1;
				}
				&.remove-text {
					color: #fff;
				}
			}
		}
	}
}

.live-search-container {
	margin-left: $sidebar-width-full + 45px;
	@extend .live-search-width;
	.input-group {
		@extend .border-radius-6;
		padding: 4px 20px 4px 0px;
		display: flex;
		align-items: center;
		border: 2px solid #e3e3e3;
		margin: 15px 0;
		&:focus {
			border: 2px solid $bg-blue;
			transition: 0.5s ease-in;
		}
		.search-link {
			margin-left: 2px;
			background-color: #3f65f1;
			height: 30px;
			width: 30px;
			position: relative;
			top: 0;
			transition: top 0.1s ease-in-out;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg {
				color: #fff;
				margin-right: 0;
			}
			&:hover {
				top: -1px;
				box-shadow: 0px 2px 2px #2c3e502f;
				background-color: #1e42c4;
			}
		}
		div.search-filter-toggle {
			label {
				@extend .ml-3, .mb-0;
				display: flex;
				right: 80px;
				align-items: center;

				span {
					margin-right: 15px;
					color: #3f65f1;
				}

				p {
					margin: 0 !important;
				}
			}
		}

		&.active {
			border: solid 2px var(--bg-blue);
		}
	}

	input#search-text {
		&::placeholder {
			position: relative;
			left: 0;
		}
		&:focus {
			::placeholder {
				display: none;
			}
		}
	}
}

div.live-search-dropdown-container {
	position: absolute;
	top: 64px;
	border-radius: 8px;
	height: max-content;
	@include box-shadow($btn-hover-box-shadow);
}

.search-result-container {
	display: none;
	padding: 15px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	z-index: 150;
	&.show {
		display: block;
		transition: height 0.6s, opacity 0.5s linear;
		height: 400px;
	}
	// @extend .live-search-width;
	@extend .live-search-width;
	background-color: #fff;
	overflow: auto;

	.row {
		p {
			font-size: 16px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.5px;
			color: #273f52;
		}

		height: 100%;

		.ingredients {
			width: 50%;
			ul.list {
				@extend .search-list;
			}
		}

		.products {
			width: 50%;
			ul.list {
				@extend .search-list;
			}
		}

		.packaging {
			width: 50%;
			ul.list {
				@extend .search-list;
				li {
					justify-content: center;
					position: relative;
					img {
						margin-left: 0.5rem;
						margin-right: auto;
					}
					a {
						position: absolute;
						justify-self: center;
					}
				}
			}
		}
	}
}

.search-filter-container {
	height: max-content;
	background: #fff;
	padding: 10px 15px;
	display: none;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	&.no-results {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	.col-12 {
		padding: 0;
	}
	&.show {
		display: block;
	}
	.search-filter-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		.advanced-search {
			margin-left: auto;
			white-space: nowrap;
			display: inline-block;
			justify-self: flex-end;
			margin-bottom: 10px;
			border: 1px solid #cedbe9;
			border-radius: 6px;
			padding: 6px 12px;
			position: relative;
			top: 0;
			transition: all 0.2s ease-in-out;
			background-color: #8585850c;
			cursor: pointer;
			&:hover {
				background-color: #3f65f1;
				color: #fff;
				border-color: #3f65f1;
				top: -1px;
			}
		}
	}

	.search-filters {
		margin: 0 0 8px;
		min-height: 37px;
		background-color: var(--very-light-blue);
		border-radius: 8px;
		display: flex;
		flex-flow: row wrap;
		// justify-content: space-around;
		justify-content: flex-start;
		padding: 10px 15px;

		label {
			margin-right: 30px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			align-items: center;
			:last-of-type {
				margin-right: 0;
			}
		}
	}

	@extend .live-search-width;
}

.products-section__content {
	.concept-products-list {
		padding: 0;
	}
	.product-item {
		width: 370px;
		display: inline-block;
		position: relative;
		margin: 10px 15px;
	}

	.ingredient-item {
		display: inline-block;
		position: relative;
		margin: 10px 20px 10px 0;
		width: 370px;
		padding: 12px 8px;
	}
}

.search-v2-grid {
	display: grid;
	grid-auto-rows: minmax(100px, auto);
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 15px;
}

.search-v2-table {
	border-collapse: separate;
	border-spacing: 0 12px;
	th {
		padding: 5px 5px 20px;
		font-size: 20px;
		color: #041928;
		font-weight: 400;
	}

	td {
		padding: 5px;
		vertical-align: middle;
	}
}

.search-v2-pagination {
	display: flex;
	justify-content: center;
	.pagination-wrapper {
		position: inherit;
	}
}
